import React, { useState } from 'react'
import { Helmet } from 'react-helmet'

import ClipLoader from 'react-spinners/ClipLoader'

import Icon from 'components/Icon'

import styles from './PreRoll.module.scss'

const PreRoll = ({ onSkip }) => {
  const [isLoading, setIsLoading] = useState(true)

  return (
    <div className={styles.preRoll}>
      <div className={styles.content}>
        <ChaturbateStream onLoad={() => setIsLoading(false)} />
      </div>
      { isLoading &&
        <div className={styles.loader}>
          <ClipLoader
            size={'3rem'}
            color={'#FF8A8A'}
          />
        </div>
      }
      { !isLoading && 
        <>
          <span className={styles.info}>Chaturbate Stream</span>
          <button className={styles.skip} onClick={onSkip} aria-label='skip'>
            <span className={styles.skipText}>Skip the Stream</span>
            <Icon className={styles.skipIcon} icon={['fas', 'forward']} />
          </button>
        </>
      }
    </div>
  )
}

const ChaturbateStream = ({ onLoad }) => {
  const src = "https://chaturbate.com/in/?track=embed&tour=6o0b&campaign=2BDBh&disable_sound=1&mobileRedirect=auto&embed_video_only=1"
  
  return (
    <>
      <Helmet>
        <link rel='preconnect dns-prefetch' href={src} crossorigin />
        <link rel='preload' as='document' href={src} />
      </Helmet>
      <div className={styles.playerContainer}>
        <iframe
          title='Chaturbate Ad'
          src={src}
          scrolling='no'
          align='middle'
          frameBorder='no'
          allowtransparency='true'
          marginHeight='0'
          marginWidth='0'
          onLoad={onLoad}
          className={styles.player}
        />
        <div
          className={styles.overlay}
          onClick={() => {
            window.open('https://chaturbate.com/in/?track=default&tour=JpRf&campaign=2BDBh&next=/female-cams/','_blank','noopener')
          }}
        ></div>
      </div>
    </>
  );
}

export default PreRoll