import React, { useState, useContext } from 'react'
import { Link } from 'gatsby'
import { useCookies } from 'react-cookie'
import cx from 'classnames'
import * as timeago from 'timeago.js'

import { PageInfoContext } from 'utils/store'

import Icon from 'components/Icon'
import Sharebox from 'components/Sharebox'

import styles from './Info.module.scss'
import { useEffect } from 'react'

const Menu = ({ videoId, ratings }) => (
  <div className={styles.menu}>
    <RatingButtons videoId={videoId} ratings={ratings} />
    <ShareButton />
    <Link to={'/legal/dmca/'} className={styles.menuItem} aria-label='Report'>
      <Icon className={styles.itemIcon} icon={['fas', 'flag']} />
    </Link>
  </div>
)

const RatingButtons = ({ videoId, ratings }) => {
  const [isClient, setIsClient] = useState(false)
  const ratingCookie = `video-rating-${videoId}`
  const [cookies, setCookie, removeCookie] = useCookies([ratingCookie])
  const isLiked = () => cookies[ratingCookie] === 'like'
  const isDisliked = () => cookies[ratingCookie] === 'dislike'
  const likes = isLiked() ? ratings.likes + 1 : ratings.likes
  const dislikes = isDisliked() ? ratings.dislikes + 1 : ratings.dislikes
  const ratingProgress = 100 * likes / (likes + dislikes)

  useEffect(() => setIsClient(true), []) // fixing ssr rehydratation issue with classnames

  const handleRating = (event) => {
    const action = event.currentTarget.getAttribute('data-rating-action')

    if ((action === 'like' && isLiked()) ||
        (action === 'dislike' && isDisliked())) {
      return removeCookie(ratingCookie, { path: '/' })
    }
    
    const expiresDate = new Date()
    expiresDate.setFullYear(expiresDate.getFullYear() + 1)

    setCookie(ratingCookie, action, {
      path: '/',
      expires: expiresDate
    })
  }

  return (
    <div key={isClient ? 'client' : 'server'} className={styles.rating}>
      <div
        className={cx(styles.ratingItem, { [styles.ratingItemActive]: isLiked() })}
        data-rating-action='like'
        onClick={handleRating} >
        <Icon className={styles.itemIcon} icon={['fas', 'heart']} />
        <span className={styles.itemText}>{likes}</span>
      </div>
      <div
        className={cx(styles.ratingItem, { [styles.ratingItemActive]: isDisliked() })}
        data-rating-action='dislike'
        onClick={handleRating} >
        <Icon className={styles.itemIcon} icon={['fas', 'heart-broken']} />
        <span className={styles.itemText}>{dislikes}</span>
      </div>
      <span className={styles.ratingBar}>
        <span className={styles.ratingProgress} style={{ width: `${ratingProgress}%` }}></span>
      </span>
    </div>
  )
}

const ShareButton = React.memo(() => {
  const [showSharebox, setShowSharebox] = useState(false)
  const { shareTitle, shareUrl } = useContext(PageInfoContext)

  const handleShare = () => {
    if (navigator.share) {
      navigator.share({
        title: 'sloppydeep.com',
        text: shareTitle,
        url: shareUrl,
      })
        .then(() => console.log('Successful share'))
        .catch((error) => console.log('Error sharing', error));
    } else  {
      setShowSharebox(true)
    }
  }

  return (
    <>
      <Sharebox shouldShow={showSharebox} onClose={() => setShowSharebox(false)} title={shareTitle} url={shareUrl} />
      <button className={styles.menuItem} onClick={handleShare} aria-label='share'>
        <Icon className={styles.itemIcon} icon={['fas', 'share']} />
        <span className={styles.itemText}>Share</span>
      </button>
    </>
  )
})

const Info = ({ videoId, title, views, publishDate, likes, dislikes }) => (
  <section className={styles.info}>
    <h1 className={styles.title}>{title}</h1>
    <div className={styles.line}>
      <div className={styles.views}>{views.toLocaleString()} views</div>
      <div className={styles.date}><time dateTime={publishDate}>{timeago.format(publishDate)}</time></div>
    </div>
    <Menu videoId={videoId} ratings={{ likes, dislikes }} />
  </section>
)

export default Info