import React from 'react'
import { Helmet } from 'react-helmet'

import styles from './LiveNow.module.scss'

const LiveNow = () => {

  if (typeof window === 'undefined') {
    return (
      <Helmet>
        <link rel='preconnect dns-prefetch' href={'https://pt.protoawe.com'} crossorigin />
      </Helmet>
    );
  }

  const fontSize = Number(window.getComputedStyle(document.body).getPropertyValue('font-size').match(/\d+/)[0])
  const rem35 = 35 * fontSize;
  const rem48 = 48 * fontSize;

  const columns = window.innerWidth <= rem35 ? 1
                : window.innerWidth <= rem48 ? 2 : 3;
  const rows = window.innerWidth <= rem35 ? 1 : 2;

  const feedUrl = `https://pt.protoawe.com/cifra?psid=DeeperStudio&psprogram=revs&pstool=212_1&site=jasmin&cobrandid=&campaign_id=&category=girl&row=${rows}&column=${columns}&background=FF8A8A&fill=0&border=0&model=inside&modelColor=0&modelFill=0&wide=1&padding=6px&width=0&height=0&imageWidth=0&imageHeight=0&stream=1&start=1&performerList=&subaffid={SUBAFFID}&legacyRedirect=1`

  return (
    <>
      <Helmet>
        <link rel='preconnect dns-prefetch' href={'https://pt.protoawe.com'} crossorigin />
      </Helmet>
      <div id="awe_livenow_container" className={styles.liveNowContainer}>
        <iframe
          title='Live now models'
          src={feedUrl}
          loading='lazy'
          scrolling='no'
          align='middle'
          frameBorder='no'
          allowtransparency='true'
          marginHeight='0'
          marginWidth='0'
          className={styles.liveNow}
        />
        <div
          className={styles.overlay}
          onClick={() => {
            window.open('//awptjmp.com/?siteId=jasmin&categoryName=girl&pageName=signup&performerName=&prm[psid]=DeeperStudio&prm[pstool]=205_1&prm[psprogram]=revs&prm[campaign_id]=&subAffId={SUBAFFID}&filters=','_blank','noopener')
          }}
        ></div>
      </div>
    </>
  )
}

export default LiveNow