import React from 'react'
import cx from 'classnames'

import Icon from 'components/Icon'

import styles from './Sharebox.module.scss'

function parseParams(object) {
  const params = Object.entries(object)
    .filter(([, value]) => value !== undefined && value !== null)
    .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(String(value))}`);

  return params.length > 0 ? `?${params.join('&')}` : '';
}

function isMobileOrTablet() {
  // SSR check
  return typeof navigator !== 'undefined' && /(android|iphone|ipad|mobile)/i.test(navigator.userAgent);
}

const TwitterButton = ({
  url, title, via, hashtags, related
}) => {
  const parsedParams = parseParams({
    url,
    text: title,
    via,
    hashtags: hashtags ? hashtags.join(',') : undefined,
    related: related ? related.join(',') : undefined,
  })

  return (
    <ShareButton
      className={styles.twitterItem}
      name='twitter'
      icon={['fab', 'twitter']}
      href={`https://twitter.com/intent/tweet${parsedParams}`}
    />
  )
}

const RedditButton = ({
  url, title
}) => {
  const parsedParams = parseParams({
    url,
    title,
  })

  return (
    <ShareButton
      className={styles.redditItem}
      name='reddit'
      icon={['fab', 'reddit-alien']}
      href={`https://www.reddit.com/submit${parsedParams}`}
    />
  )
}

const TelegramButton = ({
  url, title
}) => {
  const parsedParams = parseParams({
    url,
    text: title,
  })

  return (
    <ShareButton
      className={styles.telegramItem}
      name='telegram'
      icon={['fab', 'telegram-plane']}
      href={`https://telegram.me/share/${parsedParams}`}
    />
  )
}

const OkButton = ({
  url, title, description, image
}) => {
  const parsedParams = parseParams({
    url,
    title,
    description,
    imageUrl: image,
  })

  return (
    <ShareButton
      className={styles.okItem}
      name='ok.ru'
      icon={['fab', 'odnoklassniki']}
      href={`https://connect.ok.ru/offer${parsedParams}`}
    />
  )
}

const VkButton = ({
  url, title, image, noParse, noVkLinks
}) => {
  const parsedParams = parseParams({
    url,
    title,
    image,
    noparse: noParse ? 1 : 0,
    no_vk_links: noVkLinks ? 1 : 0,
  })

  return (
    <ShareButton
      className={styles.vkItem}
      name='ok.ru'
      icon={['fab', 'vk']}
      href={`https://vk.com/share.php${parsedParams}`}
    />
  )
}

const WeiboButton = ({
  url, title, image
}) => {
  const parsedParams = parseParams({
    url,
    title,
    pic: image
  })

  return (
    <ShareButton
      className={styles.weiboItem}
      name='weibo'
      icon={['fab', 'weibo']}
      href={`http://service.weibo.com/share/share.php${parsedParams}`}
    />
  )
}

const WhatsappButton = ({
  url, title
}) => {
  const parsedParams = parseParams({
    text: title ? title + ' ' + url : url
  })

  return (
    <ShareButton
      className={styles.whatsappItem}
      name='whatsapp'
      icon={['fab', 'whatsapp']}
      href={`https://${isMobileOrTablet() ? 'api' : 'web'}.whatsapp.com/send${parsedParams}`}
    />
  )
}

const ShareButton = ({ name, icon, className, href }) => (
  <a className={cx(styles.listItem, className)} href={href} target='_blank' rel='noopener noreferrer'>
    <Icon className={styles.itemIcon} icon={icon} />
    <span className={styles.itemName}>{name}</span>
  </a>
)

const ShareList = ({ url, title }) => (
  <div className={styles.list}>
    <TwitterButton url={url} title={title} />
    <RedditButton url={url} title={title} />
    <TelegramButton url={url} title={title} />
    <OkButton url={url} title={title} />
    <VkButton url={url} title={title} />
    <WeiboButton url={url} title={title} />
    <WhatsappButton url={url} title={title} />
  </div>
)

const Linkbox = ({ url }) => (
  <div className={styles.linkbox}>
    <input type="text" value={url} disabled className={styles.linkboxText} readOnly />
    <button className={styles.copyButton} onClick={() => navigator && navigator.clipboard.writeText(url)}>Copy</button>
  </div>
)

const Sharebox = ({ shouldShow, onClose, url, title }) => (
  <>
    <div className={cx(styles.overlay, { [styles.show]: shouldShow })} onClick={onClose}></div>
    <div className={cx(styles.shareBox, { [styles.show]: shouldShow })}>
      <Icon className={styles.closeIcon} icon={['fas', 'times']} onClick={onClose} />
      <ShareList url={url} title={title} />
      <Linkbox url={url} />
    </div>
  </>
)

export default Sharebox