import React from 'react'
import { Link } from 'gatsby'
import Img from 'gatsby-image'
import StarRatings from 'react-star-ratings'

import styles from './Meta.module.scss'

const Meta = ({ description, model, categories }) => {

  return (
    <div className={styles.meta}>
      <section className={styles.model}>
        <Link to={`/models/${model.slug}/`} className={styles.profilePicture} aria-label={model.primaryAka.nickname}>
          <Img fluid={{...model.profilePicture.childImageSharp.fluid, aspectRatio: 1 }} className={styles.profilePictureImg} />
        </Link>
        <div className={styles.modelMeta}>
          <Link to={`/models/${model.slug}/`} className={styles.modelNickname}>
            <h1>{model.primaryAka.nickname}</h1>
          </Link>
          <div className={styles.modelRating}>
            <StarRatings
              rating={model.rating}
              starEmptyColor="#535353"
              starRatedColor="#EBEBEB"
              starDimension=".8125rem"
              starSpacing=".01rem"
            />
          </div>
        </div>
      </section>
      <div className={styles.metaContent}>

        <section className={styles.block}>
          <h1 className={styles.blockTitle}>Description</h1>
          <p className={styles.blockParagraph}>{description}</p>
        </section>

        <section className={styles.block}>
          <h1 className={styles.blockTitle}>Categories</h1>
          <ul className={styles.blockList}>
            {categories.map((category, idx) => (
              <li key={idx} className={styles.listItem}>
                <Link to={`/categories/${category.slug}/`}>{category.name}</Link>
              </li>
            ))}
          </ul>
        </section>

      </div>
    
    </div>
  )
}

export default Meta