import React, { useContext, useMemo } from 'react'
import { graphql, navigate } from 'gatsby'
import { Helmet } from 'react-helmet'

import { UserInfoContext, PageInfoContext } from 'utils/store'
import { secondsToISOString } from 'utils/functions'

import SEO from 'components/SEO'
import Player from 'components/Player'
import Info from './Info'
import Meta from './Meta'
import LiveNow from './LiveNow'
import VideoCard from 'components/VideoCard'
import PartnersLinks from 'components/PartnersLinks'
import Banner from 'components/Banner'

import styles from './Video.module.scss'

const VideoPage = ({ data }) => {
  const userInfo = useContext(UserInfoContext)
  const { video, site: { siteMetadata } } = data

  const videoSchema = useMemo(() => ({
    "@context": "http://schema.org/",
    "@type": "VideoObject",
    "name": video.title,
    "duration": secondsToISOString(video.duration),
    "thumbnailUrl": `${siteMetadata.siteUrl}${video.thumbnail.publicURL}`,
    "uploadDate": video.publishDate,
    "description": video.seoDescription,
    "keywords": video.seoKeywords
  }), [video.videoId])

  const breadcrumb = useMemo(() => ({
    '@context': 'http://schema.org',
    '@type': 'BreadcrumbList',
    'itemListElement': [
      {
        "@type": "ListItem",
        "position": 1,
        "item": {
          "@id": `${siteMetadata.siteUrl}/videos/`,
          "name": "Videos"
        }
      },
      {
        "@type": "ListItem",
        "position": 2,
        "item": {
          "@id": `${siteMetadata.siteUrl}/videos/${video.slug}/`,
          "name": video.title
        }
      },
    ]
  }), [video.videoId])

  if (userInfo && video.model.bannedCountries.includes(userInfo.country_code)) {
    navigate('/country-block/')
    return null;
  }
  
  return (
    <PageInfoContext.Provider
      value={{ 
        shareUrl: `${siteMetadata.siteUrl}/videos/${video.slug}/`,
        shareTitle: `Watch Latest ${video.model.primaryAka.nickname} XXX Cam Video` 
      }}
    >
      <SEO
        breadcrumb={breadcrumb}
        schemas={[ videoSchema ]}
        title={`${video.title} | SloppyDeep.com`}
        description={video.seoDescription}
        keywords={video.seoKeywords.join(', ')}
        imagePath={video.thumbnail.publicURL}
        imageAlt={video.thumbnailAlt}
        pagePath={`/videos/${video.slug}/`}
        robots='index, follow'
      />
      <Helmet>
        { /* prefetch awempire api for player and live-now */}
        <link rel='preconnect dns-prefetch' href='https://pt.protoawe.com' crossorigin />
        <link rel='preconnect dns-prefetch' href='https://api-protected.protoawegw.com' crossorigin />
      </Helmet>
      <main className={`${styles.videoScreen} screen-container`}>
        <div className={styles.video}>
          <Player
            origin={video.origin}
            extraInfos={video.extraInfos}
            title={video.title}
            embed={video.embed}
          />
          <Info
            videoId={video.videoId}
            title={video.title}
            views={video.views}
            publishDate={video.publishDate}
            likes={video.likes}
            dislikes={video.dislikes}
          />
          <Meta
            description={video.description}
            model={video.model}
            categories={video.categories}
          />
        </div>

        <section className={styles.related}>
          <h1 className={styles.sectionTitle}>Featured Videos</h1>
          <div className={`video-cards-container ${styles.cardsContainer}`}>
            {video.related.map((relatedVideo, idx) => <VideoCard key={idx} data={relatedVideo} displayRow />)}
          </div>
        </section>

        <aside className={styles.liveNow}>
          <h1 className={styles.sectionTitle}>Live now</h1>
          <LiveNow />
        </aside>

        <aside className={styles.banner}>
          <Banner.Stripcash />
        </aside>

        {video.partnersLinks.length > 0 &&
          <aside className={styles.partnersLinks}>
            <h1 className={styles.sectionTitle}>Sloppy Partners</h1>
            <PartnersLinks links={video.partnersLinks} />
          </aside>
        }

      </main>
    </PageInfoContext.Provider>
  )
}

export const query = graphql`
  query VideoPageQuery($id: String!) {

    video: sloppyDeepVideo(id: {eq: $id}) {
      videoId
      slug
      title
      description
      seoDescription
      origin
      embed
      likes
      dislikes
      views
      duration
      publishDate
      seoKeywords
      extraInfos
      partnersLinks

      thumbnail {
        publicURL
      }
      thumbnailAlt

      categories {
        name
        slug
      }

      model {
        slug
        rating
        bannedCountries

        primaryAka {
          nickname
        }

        profilePicture {
          childImageSharp {
            fluid(maxWidth: 60, maxHeight: 60, quality: 80, cropFocus: CENTER) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }

      }

      related {
        slug
        title
        views
        publishDate
        duration
        isHd
        thumbnail {
          childImageSharp {
            fluid(maxWidth: 380, maxHeight: 214, quality: 80, cropFocus: CENTER) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
        thumbnailAlt
        preview
        model {
          slug
          primaryAka {
            nickname
          }
        }
      }

    }

    site {
      siteMetadata {
        siteUrl
      }
    }

  }
`

export default VideoPage