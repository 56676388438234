import React, { useState, useEffect } from 'react'
import { Helmet } from 'react-helmet'

import ClipLoader from 'react-spinners/ClipLoader'

import PreRoll from 'components/PreRoll'

import styles from './Player.module.scss'

const Player = ({ origin, embed, title, extraInfos }) => {
  const [showPreRoll, setShowPreRoll] = useState(false)
  const [isLoading, setIsLoading] = useState(true)

  const src = embed.replace('{CONTAINER}', styles.player).replace('embed/tbplyr', 'tube-player')

  useEffect(() => {
    const videosWatched = parseInt(localStorage.getItem('videos-watched')) || 0

    if (videosWatched % 3 === 0) setShowPreRoll(true)

    return () => {
      localStorage.setItem('videos-watched', videosWatched + 1);
    }

  }, [])
  
  return (
    <>
    <Helmet>
      <link rel='preconnect dns-prefetch' href={src} crossorigin />
      <link rel='preload' as='document' href={src} />
    </Helmet>
    <div className={styles.playerContainer}>

      { showPreRoll &&
        <div className={styles.preRollContainer}>
          <PreRoll onSkip={() => setShowPreRoll(false)} />
        </div>
      }

      { isLoading &&
        <div className={styles.loader}>
          <ClipLoader
            size={'3rem'}
            color={'#FF8A8A'}
          />
        </div>
      }

      <iframe
        title={title}
        src={src}
        allowFullScreen={true}
        frameBorder='0'
        onLoad={() => setIsLoading(false)}
        className={styles.player}
      />
      
    </div>
    </>
  )
  
}

export default Player